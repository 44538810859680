import PropTypes from 'prop-types'
import {
  Table, Select,
  Button, Input,
  DatePicker,
} from 'antd'
import Helper from 'utils/Helper'

const Ottopay = ({
  dataOttopay, metaOttopay, handlePage,
  handleSearch, isFetching, handleLimit,
  handleReport, limitList, isExport,
  statusList, handleChangeStatus,
  handleChangeDate,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, record, index) => (metaOttopay.per_page * (metaOttopay.current_page - 1)) + index + 1,
    },
    {
      title: 'Transaction Number',
      dataIndex: 'transaction_number',
      key: 'transaction_number',
      render: text => text || '-',
    },
    {
      title: 'Registered Number',
      dataIndex: 'acc_registered_number',
      key: 'acc_registered_number',
      render: text => text || '-',
    },
    {
      title: 'DPLK Amount',
      dataIndex: 'dplk_amount',
      key: 'dplk_amount',
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Jagadiri Amount',
      dataIndex: 'jagadiri_amount',
      key: 'jagadiri_amount',
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Gross Amount',
      dataIndex: 'gross_amount',
      key: 'gross_amount',
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Transaction Date',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      render: text => text || '-',
    },
    {
      title: 'Transaction Paid Date',
      dataIndex: 'transaction_paid_date',
      key: 'transaction_paid_date',
      render: text => text || '-',
    },
    {
      title: 'Payment Channel',
      dataIndex: 'source_payment',
      key: 'source_payment',
      render: text => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => <span className="text-capitalize">{text || '-'}</span>,
    },
  ]

  return (
    <div>
      <h6>
        Filter By :
      </h6>
      <div className="pb-3 d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <div className="mr-2">
            <Select
              placeholder="Limit"
              style={{ width: 120 }}
              onChange={handleLimit}
              className="mr-2"
              allowClear
            >
              {limitList.map(item => <Select.Option key={item.id} value={item.label}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-2">
            <Select
              placeholder="Status"
              style={{ width: 120 }}
              onChange={handleChangeStatus}
              allowClear
            >
              {statusList.map(item => <Select.Option key={item.id} value={item.value}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-2">
            <DatePicker.RangePicker
              format="YYYY-MM-DD"
              placeholder={['Start Date', 'End Date']}
              onChange={handleChangeDate}
            />
          </div>
          <div className="mr-2">
            <Input.Search
              allowClear
              placeholder="Search Transaction Number"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div className="mr-2 ">
            <Button
              loading={isExport}
              type="primary"
              className="align-items-center"
              onClick={handleReport}
            >
              Export
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        loading={isFetching}
        columns={columns}
        dataSource={dataOttopay}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: metaOttopay ? metaOttopay.per_page : 10,
          total: metaOttopay ? metaOttopay.total_count : dataOttopay.length,
          current: metaOttopay ? metaOttopay.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

Ottopay.propTypes = {
  dataOttopay: PropTypes.array,
  metaOttopay: PropTypes.object,
  isFetching: PropTypes.bool,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  isExport: PropTypes.bool,
  handleReport: PropTypes.func,
  handleLimit: PropTypes.func,
  limitList: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  statusList: PropTypes.array,
  handleChangeDate: PropTypes.func,
}

export default Ottopay
